import React from "react";
import classes from "./Button.module.scss";
import Cookies from 'js-cookie';
import { Link } from "react-router-dom";

const Button = ({children, login, href, white}) => {
  const lang = Cookies.get('i18next');
  const PLATFORM_LINK = 'https://user.imarkets-trade.one';
  return (
    <>
      {login ?
        <a href={`${PLATFORM_LINK}/login?lang=${lang}`} className={`${classes.button}`}>
          <span>{children}</span>
        </a>
        :
        <Link 
          to={!href ? `${PLATFORM_LINK}/signup?lang=${lang}` : `/${lang}/${href}`} 
          className={`${classes.button} ${white && classes.white}`}
        >
          <span>{children}</span>
        </Link>
      }
    </>
  );
};

export default Button;
